<template>
  <div :class="$style.root">
    <div class="clearfix">
      <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}}</h1>
      <VBtn v-if="isReviewer || isSpectator" class="float-right mt-4" @click="$router.push({ name: 'pkopir/RegistryListView'})" depressed small color="primary">
        Рейтинг контрагентов
      </VBtn>
      <VBtn depressed @click="$router.push({ name: 'pko/PkoDashboardView'})">
        <VIcon v-text="'mdi-arrow-left'" />
        Назад
      </VBtn>
    </div>
    <CounterpartyList
        :items="counterparties.items"
        :count="counterparties.count"
        :page="page"
        :size="size"
        :loading="loading"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import { ROLES } from '@/store/user/enums';
import CounterpartyList from '@/components/pkopir/CounterpartyList/CounterpartyList';
export default {
  name: 'CounterpartyListView',
  components: {
    CounterpartyList,
  },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
  computed: {
    ...mapGetters({
      loading: 'pkopir/pending',
      counterparties: 'pkopir/counterparties',
      hasRole: 'user/hasRole',
    }),
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'pkopir');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkopir');
    }
  },
  methods: {
    ...mapActions({
      fetchCounterpartyList: 'pkopir/fetchCounterpartyList',
    }),
    onUpdateState: debounce(function() {
      const { page, size, filter } = this;
      this.fetchCounterpartyList({ page, size, filter });
    }, 500),
  },
  watch: {
    page: function() {
      this.onUpdateState();
    },
    size: function() {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function() {
        this.onUpdateState();
      }
    }
  },
  mounted() {
    this.onUpdateState();
  }
}
</script>

<style module lang="scss">
.root {}
</style>
